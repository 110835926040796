import { Dropdown, Collapse, Modal, Offcanvas, Carousel } from 'bootstrap';

//Components
import '../scss/theme.scss';
import './components/off-canvas.js';
import './components/carousal.js';
import './components/sticky-header.js';

//Images
import '../image/site-logo.png';
import '../image/hero-img.png';
import '../image/fp-bg-tours.png';
import '../image/tour-img.png';
import '../image/partner-slide-1.png';
import '../image/partner-slide-2.png';
import '../image/partner-slide-3.png';
import '../image/fp-bg-testimonials.png';
import '../image/footer-bg-cta.png';
import '../image/top-nav-bg.png';
import '../image/header-default.png';

window.addEventListener('DOMContentLoaded', function() {
	//SVG INJECTOR
	const elementsToInject = document.querySelectorAll('.inject-me');
	const injectorOptions = {
		evalScripts: 'once'
	};
	let injector = new SVGInjector(injectorOptions);
	if (elementsToInject.length) {
		injector.inject(elementsToInject);
	}
}, false);

//PARTNER SLIDER
var multipleCardCarousel = document.querySelector(
	"#partnerSlider"
  );
//   if (window.matchMedia("(min-width: 768px)").matches) {
  if (window.matchMedia("(min-width: 992px)").matches) {
	let carousel = new Carousel(multipleCardCarousel, {
	  interval: false,
	});
	var carouselWidth = jQuery("#partnerSlider .carousel-inner")[0].scrollWidth;
	var cardWidth = jQuery("#partnerSlider .carousel-item").width();
	var scrollPosition = 0;
	jQuery("#partnerSlider .carousel-control-next").on("click", function () {
	  if (scrollPosition < carouselWidth - cardWidth * 4) {
		scrollPosition += cardWidth;
		jQuery("#partnerSlider .carousel-inner").animate(
		  { scrollLeft: scrollPosition },
		  600
		);
	  }
	});
	jQuery("#partnerSlider .carousel-control-prev").on("click", function () {
	  if (scrollPosition > 0) {
		scrollPosition -= cardWidth;
		jQuery("#partnerSlider .carousel-inner").animate(
		  { scrollLeft: scrollPosition },
		  600
		);
	  }
	});
  } else {
	jQuery(multipleCardCarousel).addClass("slide");
  }



//HOTSPOT SLIDER TRIGGER NEXT SLIDE TO CLOSE OPEN POPUPS
var but = document.querySelector(".hotspt-slide-next");
var timer = setInterval(function () {but.click();},8000);
jQuery('#carouselImageHotspot').hover(function(ev){
	clearInterval(timer);
}, function(ev){
	timer = setInterval(function () {but.click();},8000);
});